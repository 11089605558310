<template>
  <div class="my_block">
    <div class="my_header_title">
      Что необходимо рассчитать
      <a-popover v-model:visible="visibles.visible0" trigger="click">
        <template #content>Речь идет о идеальном газе, температура гибробака = константа, сила упругой деформации резиновой мембраны не учитывается. Производительность насоса изменяется линейно в промежутке между нижним и верхним давлением. Расход воды, вызвавший включение насоса, не изменяется во время заполнения гидробака.</template>
        <sup class="popover">?</sup>
      </a-popover>
    </div>
    <div class="my_header_select"><a-select v-model:value="type_calc" class="my_select" :options="types_calc"></a-select></div>

    <div class="my_calc" v-show="1 == type_calc">
      <div class="my_field">
        <label>
          {{ power1.name }}, {{ power1.ed }}
          <a-popover v-model:visible="visibles.visible_power1" v-if="power1.tooltip" trigger="click">
            <template #content>{{ power1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power1.value" class="my_input" :placeholder="power1.name" @change="changePower1"/>
      </div>
      <div class="my_field">
        <label>
          {{ power2.name }}, {{ power2.ed }}
          <a-popover v-model:visible="visibles.visible_power2" v-if="power2.tooltip" trigger="click">
            <template #content>{{ power2.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power2.value" class="my_input" :placeholder="power2.name" @change="changePower2"/>
      </div>
      <div class="my_field">
        <label>
          {{ power0.name }}, {{ power0.ed }}
          <a-popover v-model:visible="visibles.visible_power0" v-if="power0.tooltip" trigger="click">
            <template #content>{{ power0.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input :value="power0.value" class="my_input" :placeholder="power0.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ rashod.name }}, {{ rashod.ed }}
          <a-popover v-model:visible="visibles.visible_rashod" v-if="rashod.tooltip" trigger="click">
            <template #content>{{ rashod.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="rashod.value" class="my_input" :placeholder="rashod.name" />
      </div>
      <div class="my_field">
        <label>
          {{ v0.name }}, {{ v0.ed }}
          <a-popover v-model:visible="visibles.visible_v0" v-if="v0.tooltip" trigger="click">
            <template #content>{{ v0.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v0.value" class="my_input" :placeholder="v0.name" />
      </div>
      <div class="my_field">
        <label>
          {{ v1.name }}, {{ v1.ed }}
          <a-popover v-model:visible="visibles.visible_v1" v-if="v1.tooltip" trigger="click">
            <template #content>{{ v1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v1.value" class="my_input" :placeholder="v1.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ v2.name }}, {{ v2.ed }}
          <a-popover v-model:visible="visibles.visible_v2" v-if="v2.tooltip" trigger="click">
            <template #content>{{ v2.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v2.value" class="my_input" :placeholder="v2.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ pV.name }}, {{ pV.ed }}
          <a-popover v-model:visible="visibles.visible_pV" v-if="pV.tooltip" trigger="click">
            <template #content>{{ pV.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="pV.value" class="my_input" :placeholder="pV.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ t.name }}, {{ t.ed }}
          <a-popover v-model:visible="visibles.visible_t" v-if="t.tooltip" trigger="click">
            <template #content>{{ t.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="t.value" class="my_input" :placeholder="t.name"/>
      </div>
      <div class="my_field">
        <label>
          {{ capa11.name }}, {{ capa11.ed }}
          <a-popover v-model:visible="visibles.visible_capa11" v-if="capa11.tooltip" trigger="click">
            <template #content>{{ capa11.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa11.value" class="my_input my_input__big" :placeholder="capa11.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ capa12.name }}, {{ capa12.ed }}
          <a-popover v-model:visible="visibles.visible_capa12" v-if="capa12.tooltip" trigger="click">
            <template #content>{{ capa12.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa12.value" class="my_input my_input__big" :placeholder="capa12.name" disabled/>
      </div>
    </div>
    <div class="my_calc" v-show="2 == type_calc">
      <div class="my_field">
        <label>
          {{ power1.name }}, {{ power1.ed }}
          <a-popover v-model:visible="visibles.visible2_power1" v-if="power1.tooltip" trigger="click">
            <template #content>{{ power1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power1.value" class="my_input" :placeholder="power1.name" @change="changePower1"/>
      </div>
      <div class="my_field"      >
        <label>
          {{ power2.name }}, {{ power2.ed }}
          <a-popover v-model:visible="visibles.visible2_power2" v-if="power2.tooltip" trigger="click">
            <template #content>{{ power2.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power2.value" class="my_input" :placeholder="power2.name" @change="changePower2"/>
      </div>
      <div class="my_field">
        <label>
          {{ power0.name }}, {{ power0.ed }}
          <a-popover v-model:visible="visibles.visible2_power0" v-if="power0.tooltip" trigger="click">
            <template #content>{{ power0.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input :value="power0.value" class="my_input" :placeholder="power0.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ rashod.name }}, {{ rashod.ed }}
          <a-popover v-model:visible="visibles.visible2_rashod" v-if="rashod.tooltip" trigger="click">
            <template #content>{{ rashod.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="rashod.value" class="my_input" :placeholder="rashod.name" />
      </div>
      <div class="my_field">
        <label>
          {{ v0.name }}, {{ v0.ed }}
          <a-popover v-model:visible="visibles.visible2_v0" v-if="v0.tooltip" trigger="click">
            <template #content>{{ v0.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v0.value" class="my_input" :placeholder="v0.name" />
      </div>
      <div class="my_field">
        <label>
          {{ v1.name }}, {{ v1.ed }}
          <a-popover v-model:visible="visibles.visible2_v1" v-if="v1.tooltip" trigger="click">
            <template #content>{{ v1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v1.value" class="my_input" :placeholder="v1.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ v2.name }}, {{ v2.ed }}
          <a-popover v-model:visible="visibles.visible2_v2" v-if="v2.tooltip" trigger="click">
            <template #content>{{ v2.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v2.value" class="my_input" :placeholder="v2.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ pV.name }}, {{ pV.ed }}
          <a-popover v-model:visible="visibles.visible2_pV" v-if="pV.tooltip" trigger="click">
            <template #content>{{ pV.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="pV.value" class="my_input" :placeholder="pV.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ capa22.name }}, {{ capa22.ed }}
          <a-popover v-model:visible="visibles.visible2_capa22" v-if="capa22.tooltip" trigger="click">
            <template #content>{{ capa22.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa22.value" class="my_input my_input__big" :placeholder="capa22.name"/>
      </div>
      <div class="my_field">
        <label>
          {{ capa21.name }}, {{ capa21.ed }}
          <a-popover v-model:visible="visibles.visible2_capa21" v-if="capa21.tooltip" trigger="click">
            <template #content>{{ capa21.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa21.value" class="my_input my_input__big" :placeholder="capa21.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ t1.name }}, {{ t1.ed }}
          <a-popover v-model:visible="visibles.visible2_t1" v-if="t1.tooltip" trigger="click">
            <template #content>{{ t1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="t1.value" class="my_input my_input__big" :placeholder="t1.name" disabled/>
      </div>
    </div>
    <div class="my_calc" v-show="3 == type_calc">
      <div class="my_field">
        <label>
          {{ power1.name }}, {{ power1.ed }}
          <a-popover v-model:visible="visibles.visible3_power1" v-if="power1.tooltip" trigger="click">
            <template #content>{{ power1.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power1.value" class="my_input" :placeholder="power1.name" @change="changePower1"/>
      </div>
      <div class="my_field">
        <label>
          {{ power2.name }}, {{ power2.ed }}
          <a-popover v-model:visible="visibles.visible3_power2" v-if="power2.tooltip" trigger="click">
            <template #content>{{ power2.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="power2.value" class="my_input" :placeholder="power2.name" @change="changePower2"/>
      </div>
      <div class="my_field">
        <label>
          {{ power0.name }}, {{ power0.ed }}
          <a-popover v-model:visible="visibles.visible3_power0" v-if="power0.tooltip" trigger="click">
            <template #content>{{ power0.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input :value="power0.value" class="my_input" :placeholder="power0.name" disabled/>
      </div>
      <div class="my_field">
        <label>
          {{ rashod.name }}, {{ rashod.ed }}
          <a-popover v-model:visible="visibles.visible3_rashod" v-if="rashod.tooltip" trigger="click">
            <template #content>{{ rashod.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="rashod.value" class="my_input" :placeholder="rashod.name" />
      </div>
      <div class="my_field">
        <label>
          {{ t.name }}, {{ t.ed }}
          <a-popover v-model:visible="visibles.visible3_t" v-if="t.tooltip" trigger="click">
            <template #content>{{ t.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="t.value" class="my_input" :placeholder="t.name"/>
      </div>
      <div class="my_field">
        <label>
          {{ capa22.name }}, {{ capa22.ed }}
          <a-popover v-model:visible="visibles.visible3_capa22" v-if="capa22.tooltip" trigger="click">
            <template #content>{{ capa22.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa22.value" class="my_input my_input__big" :placeholder="capa22.name"/>
      </div>
      <div class="my_field">
        <label>
          {{ capa21.name }}, {{ capa21.ed }}
          <a-popover v-model:visible="visibles.visible3_capa21" v-if="capa21.tooltip" trigger="click">
            <template #content>{{ capa21.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="capa21.value" class="my_input my_input__big" :placeholder="capa21.name" disabled/>
      </div>
      <div class="my_field" style="display:none;"></div>

      <div class="my_field">
        <label>
          {{ v0_.name }}, {{ v0_.ed }}
          <a-popover v-model:visible="visibles.visible3_v0" v-if="v0_.tooltip" trigger="click">
            <template #content>{{ v0_.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v0_.value" class="my_input" :placeholder="v0_.name" disabled/>
      </div>

      <div class="my_field">
        <label>
          {{ v1_.name }}, {{ v1_.ed }}
          <a-popover v-model:visible="visibles.visible3_v1_" v-if="v1_.tooltip" trigger="click">
            <template #content>{{ v1_.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v1_.value" class="my_input" :placeholder="v1_.name" disabled/>
      </div>

      <div class="my_field">
        <label>
          {{ v2_.name }}, {{ v2_.ed }}
          <a-popover v-model:visible="visibles.visible3_v2_" v-if="v2_.tooltip" trigger="click">
            <template #content>{{ v2_.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="v2_.value" class="my_input" :placeholder="v2_.name" disabled/>
      </div>

      <div class="my_field">
        <label>
          {{ pV_.name }}, {{ pV_.ed }}
          <a-popover v-model:visible="visibles.visible3_pV_" v-if="pV_.tooltip" trigger="click">
            <template #content>{{ pV_.tooltip }}</template>
            <sup class="popover">?</sup>
          </a-popover>
        </label>
        <a-input v-model:value="pV_.value" class="my_input" :placeholder="pV_.name" disabled/>
      </div>
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      visibles:{
        visible0: false,

        visible_power1: false,
        visible2_power1: false,
        visible3_power1: false,

        visible_power2: false,
        visible2_power2: false,
        visible3_power2: false,
        
        visible_power0: false,
        visible2_power0: false,
        visible3_power0: false,
        
        visible_rashod: false,
        visible2_rashod: false,
        visible3_rashod: false,
        
        visible_v0: false,
        visible2_v0: false,
        visible3_v0: false,
        
        visible_v1: false,
        visible2_v1: false,
        visible3_v1: false,
        
        visible_v2: false,
        visible2_v2: false,
        visible3_v2: false,
        
        visible_pV: false,
        visible2_pV: false,
        visible3_pV: false,

        visible_t: false,
        visible2_t: false,
        visible3_t: false,

        visible_capa11: false,
        visible2_capa11: false,
        visible3_capa11: false,
        
        visible_capa12: false,
        visible2_capa12: false,
        visible3_capa12: false,
        
        visible_capa22: false,
        visible2_capa22: false,
        visible3_capa22: false,
        
        visible_capa21: false,
        visible2_capa21: false,
        visible3_capa21: false,
        
        visible_t1: false,
        visible2_t1: false,
        visible3_t1: false,

        visible_v1_: false,
        visible2_v1_: false,
        visible3_v1_: false,
        
        visible_v2_: false,
        visible2_v2_: false,
        visible3_v2_: false,
        
        visible_pV_: false,
        visible2_pV_: false,
        visible3_pV_: false
      },
      type_calc: 1,
      types_calc: [
        {
          value: 1,
          label: 'Производительность насоса',
        },
        {
          value: 2,
          label: 'Время заполнения гидроаккумулятора',
        },
        {
          value: 3,
          label: 'Потребный объем бака',
        }
      ],

      power2: {
          name: "P2",
          tooltip: "Верхнее давление заданное",
          ed: "АТМ",
          value: ''
      },
      
      power1: {
        name: "P1",
        tooltip: "Нижнее давление заданное",
        ed: "АТМ",
        value: ''
      },
      
      rashod: {
        name: "расход",
        tooltip: "Расход в системе: (v\t) литры в секунду (примечание - падение давления до уровня Р1 вызвано расходом воды из Г.Б., можно считать v\t =0,  но правильнее учесть расход через 1 кран, типовое значение  ~ 0,067 л/сек или 4 л/мин)Время на заполнение от Р1 до Р2 : Т (сек) (примечание - частые срабатывния насоса при малом значении Т ведут к ускоренному износу оборудования, рекомендуем не менее чем 30 секунд)",
        ed: "л/мин",
        value: 10
      },
      
      v0: {
        name: "V0",
        tooltip: "Паспортный объем гидробака",
        ed: "л",
        value: 150
      },
      t: {
        name: "t заполнения",
        tooltip: "Время заполнения гидроаккумулятора. Примечание:  нормальный гидробак заполняется 30сек или более. Если заполняется быстрее - надо использовать Г.Б. большей емкости, изменить параметры давления или использовать насос с меньшей производительностью",
        ed: "сек",
        value: 40
      },
      capa22: {
        name: "Производительность насоса средняя",
        tooltip: "для рабочего интервала р1--р2",
        ed: "м3/час",
        value: 3.5
      }
    }
  },
  computed:{
    power0(){
      return {
          name: "P0",
          tooltip: "Давление преднакачки. Давление Р0 не должно быть больше Р1 !!!. (Примечание: желательно что бы Р0=~0,8*Р1. Иначе будет провал в давлении при опустошении Г.Б. до p1, а кроме того мембрана бака будет быстрее изнашиваться)",
          ed: "АТМ",
          value: Number.parseFloat(this.power1.value * 0.8).toFixed(2)
      }
    },
    v1(){
      let res = this.power0.value / this.power1.value * this.v0.value;
      if(!res) res = 0;
      return {
          name: "V1",
          tooltip: "Доступный объём гидробака при давлении преднакачки p0 и нижнем давлении p1",
          ed: "л",
          value: Number.parseFloat(res).toFixed(2)
      }
    },
    v2(){
      let res = this.v1.value * this.power1.value / this.power2.value;
      if(!res) res = 0;
      return {
          name: "V2",
          tooltip: "Рабочий объём гидробака при известных условиях p0 p1 p2. =>> V0×0,8×p1/p2 = V2",
          ed: "л",
          value: Number.parseFloat(res).toFixed(2)
      }
    },
    pV(){
      let res = this.v1.value - this.v2.value;
      if(!res) res = 0;
      return {
        name: "Полезный объем",
        tooltip: "Полезный объем гидробака при давлении Р2-Р1 преднакачки Р0",
        ed: "л",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    capa11(){
      let res = 60/this.t.value * this.pV.value +  Number.parseFloat(this.rashod.value);
      console.log(60/this.t.value);
      if(!res) res = 0;
      return {
        name: "Производительность насоса средняя",
        tooltip: "для рабочего интервала р1--р2",
        ed: "л/мин",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    capa21(){
      let res =this.capa22.value/60 * 1000 +  Number.parseFloat(this.rashod.value);
      console.log(res, this.capa22.value);
      if(!res) res = 0;
      return {
        name: "Производительность насоса средняя",
        tooltip: "для рабочего интервала р1--р2",
        ed: "л/мин",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    capa12(){
      let res = this.capa11.value * 60 / 1000;
      if(!res) res = 0;
      return {
        name: "Производительность насоса средняя",
        tooltip: "для рабочего интервала р1--р2",
        ed: "м3/час",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    t1(){
      let res = this.pV.value * 60 / (this.capa21.value - this.rashod.value);
      if(!res) res = 0;
      return {
        name: "т заполнения",
        tooltip: "Время заполнения гидроаккумулятора. Примечание:  нормальный гидробак заполняется 30сек или более. Если заполняется быстрее - надо использовать Г.Б. большей емкости, изменить параметры давления или использовать насос с меньшей производительностью",
        ed: "сек",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    pV_(){
      let res = this.t.value * ( this.capa21.value - this.rashod.value) / 60;
      if(!res) res = 0;
      return {
        name: "Полезный объем",
        tooltip: "Полезный объем гидробака при давлении Р2-Р1 преднакачки Р0",
        ed: "л",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    v1_(){
      let res = this.power2.value * this.pV_.value / ( this.power2.value - this.power1.value);
      if(!res) res = 0;
      return {
        name: "V1",
        tooltip: "Доступный объём гидробака при давлении преднакачки p0 и нижнем давлении p1",
        ed: "л",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    v0_(){
      let res = this.v1_.value * this.power1.value / this.power0.value;
      if(!res) res = 0;
      return {
        name: "V0",
        tooltip: "Паспортный объем гидробака",
        ed: "л",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
    v2_(){
      let res = this.v1_.value * this.power1.value / this.power2.value;
      if(!res) res = 0;
      return {
        name: "V2",
        tooltip: "Рабочий объём гидробака при известных условиях p0 p1 p2. =>> V0×0,8×p1/p2 = V2",
        ed: "л",
        value: Number.parseFloat(res).toFixed(2)
      }
    },
  },
  methods:{
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changePower1: function(){
      this.power1.value = this.power1.value.replace(',', '.');
    },
    changePower2: function(){
      this.power2.value = this.power2.value.replace(',', '.');
    }
  }
}
</script>

<style scoped>
  *, :after, :before{box-sizing: inherit;}
  .my_block{
    box-sizing: border-box;margin-bottom: 10px;
  }
  .my_calc{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
  }
  .my_header_title{
    margin: 8px 0px;
    font-weight: bold;
    font-size: 1.1em;
  }
  .my_header_select{
   margin-bottom: 10px;
  }
  .my_field{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .my_field:nth-child(2n){
    align-items: end;
  }
  .my_field label{
    margin-bottom: 5px;
    font-size: 0.9em;
  }
  .my_select{
    width:100%;
    margin-bottom: 10px;
  }
  .my_input{
    width:100px;
  }
  .my_input[disabled]{
    border-color: #10a916eb;
  }
  .my_input__big{
    width: 100%;
  }
  .popover{
    border: 1px solid #ccc;padding: 0px 5px;border-radius: 50%;font-size: 10px;cursor: pointer;margin-left: 5px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-sizing: border-box;
  }
</style>
